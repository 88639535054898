<template>
  <div>
    <!-- 养老报告详情 -->
    <table class="table" v-if="elderlyDataType === 'ARCHIVE'">
      <caption class="text-center">适老化改造服务报告</caption>
      <tbody>
      <tr>
        <td class="td1111">
          <div class="userimg float-left">
            <img width="107" height="135" src="https://file.chinaylzl.com/pro/2016/other/defaultHead.jpg"
                 onerror="this.src = 'https://file.chinaylzl.com/pro/2016/other/defaultHead.jpg'">
          </div>
        </td>
        <td colspan="4">
          <div class="tableinfo">
            <div class="frow">
              <div class="fcol w200">
                <label> <span class="font14">姓</span> <span class="font14">名：</span>
                </label> <label><span class="font14"><a>{{ table.oldPersonName }}</a></span></label>
              </div>
              <div class="fcol">
                <label>报告编号：</label> <label><span class="font14"><a>{{ table.reformNo }}</a></span></label>
              </div>

            </div>
            <div class="frow">
              <div class="fcol w200">
                <label>联系电话：</label> <label><span class="font14">{{ table.oldPersonContact }}</span></label>
              </div>
              <div class="fcol">
                <label><span class="font14">性</span><span class="font14">别：</span></label> <label><span class="font14">{{
                  table.oldPersonGender
                }}</span></label>
              </div>
            </div>
            <div class="frow">
              <div class="fcol">
                <label>出生日期：</label> <label><span class="font14">{{ table.oldPersonBirthday }}</span></label>
              </div>
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <td class="text-right"><span class="font14">服务机构：</span></td>
        <td class="text-left" colspan="2"><span class="font14">{{ table.reformOrgName }}</span></td>
        <td class="text-right"><span class="font14">类型：</span></td>
        <td class="text-left"><span class="font14">适老化改造</span></td>
      </tr>
      <tr>
        <td class="text-right"><span class="font14">服务方案：</span></td>
        <td class="text-left" colspan="2"><span class="font14"><a href="#">{{ table.plan }}</a></span></td>
        <td class="text-right"><span class="font14">评估员：</span></td>
        <td class="text-left">
          <span class="font14">{{ table.assessorName }}</span><br>
          <!-- <span class="font14">-</span> -->
        </td>
      </tr>
      <tr>
        <td class="text-right"><span class="font14">初步评估结果：</span></td>
        <td class="text-left" colspan="4">
        <span class="font14">
            <a>{{ table.preEvalResult }}</a>
        </span>
        </td>
      </tr>
      <tr>
        <td class="text-right"><span class="font14">最终评估结果：</span></td>
        <td class="text-left" colspan="4">
          <span class="font14">{{ table.finalEvalResult }}</span>
        </td>
      </tr>
      <tr>
        <td class="text-right"><span class="font14">审核意见：</span></td>
        <td class="text-left" colspan="4">
          <span class="font14">{{ table.reviewComments }}</span>
        </td>
      </tr>
      <tr>
        <td class="text-right"><span class="font14">专家意见：</span></td>
        <td class="text-left" colspan="4"><span class="font14">{{ table.expertComments }}</span>
        </td>
      </tr>
      <tr>
        <td class="text-right"><span class="font14">适老化产品明细：</span></td>
        <td class="text-left" colspan="4">
          <span class="font14">{{ table.productDetail }}</span>
        </td>
      </tr>
      <tr>
        <td class="text-right"><span class="font14">改造前照片:</span></td>
        <td class="text-left" colspan="2">
          <div class="userimg float-left">
            <el-image
                v-for="(item,index) in table.beforeImgUrls"
                :key="index"
                style="width: 100px; height: 100px"
                :src="item"

                :preview-src-list="[item]"
            ></el-image>
            <!-- <img width="107" v-for="item in table.beforeImgUrls" :key="item" height="135" :src="item"> -->
            <!-- <img width="107" height="135" src="https://file.chinaylzl.com/pro/2016/other/defaultHead.jpg" onerror="this.src = 'https://file.chinaylzl.com/pro/2016/other/defaultHead.jpg'">
            <img width="107" height="135" src="https://file.chinaylzl.com/pro/2016/other/defaultHead.jpg" onerror="this.src = 'https://file.chinaylzl.com/pro/2016/other/defaultHead.jpg'">
            <img width="107" height="135" src="https://file.chinaylzl.com/pro/2016/other/defaultHead.jpg" onerror="this.src = 'https://file.chinaylzl.com/pro/2016/other/defaultHead.jpg'">
            <img width="107" height="135" src="https://file.chinaylzl.com/pro/2016/other/defaultHead.jpg" onerror="this.src = 'https://file.chinaylzl.com/pro/2016/other/defaultHead.jpg'">
            <img width="107" height="135" src="https://file.chinaylzl.com/pro/2016/other/defaultHead.jpg" onerror="this.src = 'https://file.chinaylzl.com/pro/2016/other/defaultHead.jpg'">

            <img width="107" height="135" src="https://file.chinaylzl.com/pro/2016/other/defaultHead.jpg" onerror="this.src = 'https://file.chinaylzl.com/pro/2016/other/defaultHead.jpg'"> -->
          </div>
        </td>
        <td class="text-right" style="width:10%"><span class="font14">改造后照片:</span></td>
        <td class="text-left" colspan="2">
          <div class="userimg float-left">
            <el-image
                v-for="(item,index) in table.afterImgUrls"
                :key="index"
                style="width: 100px; height: 100px"
                :src="item"

                :preview-src-list="[item]"
            ></el-image>
            <!-- <img width="107" v-for="item in table.afterImgUrls" :key="item" height="135" :src="item"> -->

            <!-- <img width="107" height="135" src="https://file.chinaylzl.com/pro/2016/other/defaultHead.jpg" onerror="this.src = 'https://file.chinaylzl.com/pro/2016/other/defaultHead.jpg'">
            <img width="107" height="135" src="https://file.chinaylzl.com/pro/2016/other/defaultHead.jpg" onerror="this.src = 'https://file.chinaylzl.com/pro/2016/other/defaultHead.jpg'">
            <img width="107" height="135" src="https://file.chinaylzl.com/pro/2016/other/defaultHead.jpg" onerror="this.src = 'https://file.chinaylzl.com/pro/2016/other/defaultHead.jpg'">

            <img width="107" height="135" src="https://file.chinaylzl.com/pro/2016/other/defaultHead.jpg" onerror="this.src = 'https://file.chinaylzl.com/pro/2016/other/defaultHead.jpg'"> -->
          </div>
        </td>
      </tr>
      <tr>
        <td class="text-right"><span class="font14">评估表，审批表，前后对比档案</span></td>
        <td class="text-left" colspan="4">
          <el-image
              v-for="(item,index) in table.otherImgUrls"
              :key="index"
              style="width: 100px; height: 100px"
              :src="item"

              :preview-src-list="[item]"
          ></el-image>
          <!-- <img width="107" v-for="item in table.otherImgUrls" :key="item" height="135" :src="item"> -->

          <!-- <img width="107" height="135" src="https://file.chinaylzl.com/pro/2016/other/defaultHead.jpg" onerror="this.src = 'https://file.chinaylzl.com/pro/2016/other/defaultHead.jpg'">
          <img width="107" height="135" src="https://file.chinaylzl.com/pro/2016/other/defaultHead.jpg" onerror="this.src = 'https://file.chinaylzl.com/pro/2016/other/defaultHead.jpg'">
          <img width="107" height="135" src="https://file.chinaylzl.com/pro/2016/other/defaultHead.jpg" onerror="this.src = 'https://file.chinaylzl.com/pro/2016/other/defaultHead.jpg'"> -->

        </td>
      </tr>
      <tr>
        <!-- <td class="text-center" colspan="5">
            <div class="btngroup">
                <a class="blue" id="downloadReport">下载养老顾问服务报告</a>
            </div>
        </td> -->
      </tr>
      </tbody>
    </table>

    <table class="table" v-if="elderlyDataType === 'BASIC_INFO'">
      <caption class="text-center">适老化改造基本信息确认</caption>
      <tbody>
      <tr>
        <td class="td1111">
          <div class="userimg float-left">
            <img width="107" height="135" src="https://file.chinaylzl.com/pro/2016/other/defaultHead.jpg"
                 onerror="this.src = 'https://file.chinaylzl.com/pro/2016/other/defaultHead.jpg'">
          </div>
        </td>
        <td colspan="4">
          <div class="tableinfo">
            <div class="frow">
              <div class="fcol w300">
                <label> <span class="font14">姓</span> <span class="font14">名：</span>
                </label> <label><span class="font14"><a>{{ table.name }}</a></span></label>
              </div>
              <div class="fcol w300">
                <label> <span class="font14">性</span> <span class="font14">别：</span>
                </label> <label><span class="font14"><a>{{ table.sex }}</a></span></label>
              </div>
              <div class="fcol">
                <label>户籍所在地：</label> <label><span
                  class="font14">{{ table.censusProvince }}{{ table.censusCity }}{{
                  table.censusArea
                }}{{ table.censusStreet }}{{ table.censusCommunity }}{{
                  table.censusAddress
                }}</span></label>
              </div>

            </div>
            <div class="frow">
              <div class="fcol w300">
                <label>年龄：</label> <label><span class="font14">{{ table.age }}</span></label>
              </div>
              <div class="fcol w300">
                <label>身份证号：</label> <label><span class="font14">{{ table.idNum }}</span></label>
              </div>
              <div class="fcol">
                <label><span class="font14">类</span><span class="font14">别：</span></label> <label><span class="font14">{{
                  table.personTypeNames
                }}</span></label>
              </div>
            </div>
            <div class="frow">
              <div class="fcol w300">
                <label>家庭联系人：</label> <label><span class="font14">{{ table.emergencyContactName }}</span></label>
              </div>
              <div class="fcol">
                <label><span class="font14">联系方式：</span></label> <label><span class="font14">{{
                  table.emergencyContactTel
                }}</span></label>
              </div>
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <td class="text-right"><span class="font14">申请改造<br/>住宅地址</span></td>
        <td class="text-left" colspan="2"><span class="font14">{{ table.usualProvince }}{{
            table.usualCity
          }}{{ table.usualArea }}{{ table.usualStreet }}{{ table.usualCommunity }}{{ table.usualAddress }}</span></td>
        <td class="text-right"><span class="font14">住宅情况</span></td>
        <td class="text-left"><span class="font14">{{ table.houseBelongOwn == 1 ? '自有' : '非自有' }}</span></td>
      </tr>
      <tr>
        <td class="text-right"><span class="font14">定位</span></td>
        <td class="text-left" colspan="2"><span class="font14">{{ table.location }}</span></td>
        <td class="text-right"><span class="font14">经纬度</span></td>
        <td class="text-left">
          <span class="font14" v-if="table.latitude != '' && table.latitude != null">{{ table.latitude }},{{ table.longitude }}</span><br>
          <!-- <span class="font14">-</span> -->
        </td>
      </tr>
      <tr>
        <td class="text-right"><span class="font14">服务人员</span></td>
        <td class="text-left" colspan="2"><span class="font14"><a href="#"> {{ table.workerName }}</a></span></td>
        <td class="text-right"><span class="font14">备注</span></td>
        <td class="text-left">
          <span class="font14">{{ (table.remark == null || table.remark == '') ? '无' : table.remark }}</span><br>
          <!-- <span class="font14">-</span> -->
        </td>
      </tr>
      <tr>
        <td class="text-right"><span class="font14">图片</span></td>
        <td class="text-left" colspan="4">
          <el-image
              v-for="(item,index) in table.imgInfoList"
              :key="index"
              style="width: 100px; height: 100px"
              :src="item.url"
              :preview-src-list="[item.url]"
          ></el-image>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import UploadImg from '@/components/UploadImg.vue'
import api from '@/store/API/api'
import axios from "axios";

export default {
  components: {
    UploadImg
  },
  data() {
    return {
      table: {},
      beforeImgsList: [],
      afterImgsList: [],
      otherImgsList: [],
      elderlyDataType: ''
    }
  },
  created() {
    console.log(this.$route);
    this.elderlyDataType = this.$route.query.elderlyDataType
    this.queryDetail()
  },
  methods: {
    ...mapActions([
      'elderReformDetai',
      'queryolderReformListDetail'
    ]),
    queryDetail() {
      let params = {
        id: this.$route.query.id
      }
      if (this.elderlyDataType === 'ARCHIVE') {
        this.elderReformDetai(params).then(res => {
          if (res.code == 200) {
            if (res.data == null) {
              this.isshow = false
            } else {
              this.isshow = true
              this.table = {
                ...res.data
              }
              this.id = res.data.id
            }

          }
        }).catch(err => {
          console.log(err);
        })
      } else if (this.elderlyDataType === 'BASIC_INFO') {
        this.queryolderReformListDetail(params, this.$route.query.id).then(res => {
          if (res.code == 200) {
            if (res.data == null) {
              this.isshow = false
            } else {
              this.isshow = true
              this.table = {
                ...res.data
              }
              this.id = res.data.id
            }
          }
        })
      }

    },
  }
}
</script>

<style scoped>

/deep/ .el-image {
  width: 220px !important;
  height: 300px !important;
  margin: 32px 16px 0 0 !important;
}

.servertable {
  position: relative;
}

.backwhite {
  background-color: #fff;
  width: 98%;
  padding: 40px 0 0 50px;
  float: left;
}

.table {
  background-color: transparent;
  max-width: 100%;
  margin-bottom: 20px;
  width: 98%;
  border-spacing: 0;
  border-collapse: collapse;

}

/* :deep .el-descriptions__header{
  display: flex;
  justify-content: center !important;

  font-size: 24px;
} */
/* :deep .el-descriptions__header .el-descriptions__title{
    color: #488fe1;
    font-size: 24px;
    padding: 0;
    font-weight: 400;
}
.img{
  width: 150px;
  height: 150px;
} */
.table tr .td1111 {
  width: 170px;
  padding-left: 24px;
}

.td1111 {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  color: #333;
  border-spacing: 0;
  border-collapse: collapse;
  margin: 0;
  box-sizing: border-box;
  font-family: "Microsoft YaHei", "微软雅黑";
  list-style: none;
  outline: none;
  text-decoration: none;
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: middle;
  font-size: 14px;
  height: 50px;
  border: 1px solid #e2e5e7;
  width: 170px;
  padding-left: 24px;

}

.float-left {
  float: left;
}

.tableinfo {
  margin-left: 1em;
}

.frow {
  width: 100%;
  display: flex;
  align-items: center;
  float: left;
  display: -moz-box;
  /* display: -webkit-box; */
  display: box;
  -moz-box-align: center;
  -webkit-box-align: center;
  display: -ms-flexbox;
  -ms-flex-align: center;
}

.tableinfo {
  margin-left: 1em;
}

.frow .fcol {
  float: left;
  /* height: 60px; */
  line-height: 60px;
  position: relative;
}

.w200 {
  width: 200px;
}

.w300 {
  width: 300px;
}

.font14 {
  font-size: 14px;
}

.table tr td {
  vertical-align: middle;
  font-size: 14px;
  height: 50px;
  border: 1px solid #e2e5e7;
}

.table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: middle;
  /* border-top: 1px solid #ddd; */
}

.table caption {
  color: #488fe1;
  font-size: 24px;
  padding: 0;
  margin-bottom: 16px;
}

.table tr td {
  vertical-align: middle;
  font-size: 14px;
  height: 50px;
  border: 1px solid #e2e5e7;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

table tr td .bz {
  color: #777676;
  line-height: 31px;
  padding-left: 17px;
  padding-right: 50px;
}

.table tr td .bz h2 {
  font-size: 18px;
  margin: 5px 0px;
  line-height: 1em;
  font-family: inherit;
  font-weight: 500;
  color: inherit;
}

.table tr td .bz p {
  padding-left: 11px;
}

.table .btngroup {
  margin: 15px 0;
}

.table .btngroup .blue {
  background-color: #488fe1;
}

.table .btngroup a {
  color: white;
  font-size: 16px;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 6px;
  margin: 0 16px;
}

.text-center {
  text-align: center;
}

.table a, .servertable .table a:visited, .servertable .table a:active, .servertable .table a:link, .servertable .table a:hover {
  color: #5093E2;
  font-size: 14px;
}

.userimg img {
  border: 1px solid #e7e7e7;
  margin-bottom: 10px;
  margin-left: 5px;
}

.servertable .table .btngroup .blue:hover {
  background-color: #3183E3;
}

.servertable .table .btngroup a:hover {
  color: white;
  font-size: 16px;
}

.servertable .table tr td .bz header {
  vertical-align: middle;
  font-size: 14px;
  height: auto;
  border: none;
  padding: 0;
}
</style>
